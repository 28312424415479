<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="种子名称">
                        <el-input
                            v-model="search.name"
                            clearable
                            placeholder="模糊搜索"
                            style="width: 160px;"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="种子编码">
                        <el-input
                            v-model="search.code"
                            clearable
                            placeholder="精确搜索"
                            style="width: 160px;"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="适合季节">
                        <el-select
                            v-model="search.matchSeason"
                            placeholder=""
                            style="width: 160px;"
                        >
                            <el-option
                                v-for="item in seasonList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="getList"
                            >查询</el-button
                        >
                        <el-button type="primary" size="medium" @click="reset"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-form>
            </template>

            <template v-slot:table="row">
                <el-button
                    type="primary"
                    size="medium"
                    @click="edit({}, '新增种子')"
                    >新增种子</el-button
                >
                <el-table
                    v-if="row.height"
                    :height="row.heightText"
                    :data="tableData"
                    style="width: 100%"
                >
                    <el-table-column
                        type="index"
                        width="50"
                        label="序号"
                        fixed="left"
                    />
                    <el-table-column
                        prop="date"
                        label="种子图片"
                        width="160"
                        align="center"
                    >
                        <template #default="scope">
                            <el-image
                                class="avatar"
                                fit="cover"
                                :src="scope.row.picture"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="种子名称"
                        width="120"
                        show-overflow-tooltip
                    />
                    <el-table-column
                        prop="code"
                        label="种子编码"
                        show-overflow-tooltip
                        width="120"
                    />
                    <el-table-column
                        prop="price"
                        label="每单位种植价格"
                        width="120"
                    >
                        <template #default="scope">
                            <span v-if="scope.row.price"
                                >¥{{ scope.row.price }}</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="matchSeason"
                        label="适合季节"
                        width="140"
                        show-overflow-tooltip
                    >
                        <template #default="scope">
                            <div>
                                <span>{{ scope.row.matchSeason |  matchSeason}}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="matchMonthRemark"
                        label="种植月份"
                        width="120"
                    />
                    <el-table-column
                        prop="harvestCycle"
                        label="收货周期"
                        width="120"
                    />
                    <el-table-column
                        prop="avgProduction"
                        label="平均产量(KG/m²)"
                        width="140"
                    />
                    <el-table-column
                        prop="priceRecycle"
                        label="每公斤回收价格(元/KG)"
                        width="170"
                    >
                        <template #default="scope">
                            <span v-if="scope.row.priceRecycle"
                                >¥{{ scope.row.priceRecycle }}</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="90">
                        <template #default="scope">
                            {{
                                scope.row.status == 0
                                    ? "下架"
                                    : scope.row.status == 1
                                    ? "上架"
                                    : ""
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="添加时间"
                        width="160"
                    />
                    <el-table-column
                        label="操作"
                        fixed="right"
                        width="220"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                @click="onOffLine(scope.row, '1')"
                                v-if="scope.row.status == 0"
                                >上架</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                @click="onOffLine(scope.row, '0')"
                                v-if="scope.row.status == 1"
                                >下架</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                @click="edit(scope.row)"
                                >编辑</el-button
                            >
                            <el-button
                                size="mini"
                                type="danger"
                                @click="del(scope.row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <el-dialog
            title="查看"
            append-to-body
            style="z-index: 5001;"
            class="my-dialog"
            :visible.sync="isVisible"
            width="800px"
        >
            <el-carousel
                v-if="isVisible"
                height="430px"
                :autoplay="false"
                trigger="click"
                :initial-index="initialIndex"
            >
                <!-- <el-carousel-item v-for="item in list" :key="item"> -->
                <el-image
                    v-if="showData.type == 1"
                    :src="showData.img"
                    fit="contain"
                    style="width: 760px;height: 430px;"
                />
                <video
                    v-else-if="showData.type == 2"
                    :src="showData.video"
                    controls="controls"
                    style="width: 760px;height: 430px;"
                >
                    您的浏览器不支持视频播放
                </video>
                <el-empty
                    v-else
                    :image-size="180"
                    description="数据格式有误！"
                />
                <!-- </el-carousel-item> -->
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false"
                    >确定</el-button
                >
            </template>
        </el-dialog>
        <addSeed
            @closeAdd="closeAdd"
            :addDialogVisible="addDialogVisible"
            :currentRow="currentRow"
        />
    </div>
</template>

<script>
import addSeed from "./components/addSeed.vue";
import { URL } from "../../../config";

export default {
    name: "landConfig",
    components: {
        addSeed
    },
    filters:{
        matchSeason(val){
            let seasonArr = val.split(",");
            let season = []
            if (seasonArr.indexOf('1') > -1) {
                season.push('春季')
            }
            if (seasonArr.indexOf('2') > -1) {
                season.push('夏季')
            }
            if (seasonArr.indexOf('3') > -1) {
                season.push('秋季')
            }
            if (seasonArr.indexOf('4') > -1) {
                season.push('冬季')
            }
            return season.join(',')
        }
    },
    data() {
        return {
            search: {
                code: "",
                matchSeason: "",
                name: ""
            },
            loading: false,
            isVisible: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            seasonList: [
                { label: "全部", value: "" },
                { label: "春季", value: 1 },
                { label: "夏季", value: 2 },
                { label: "秋季", value: 3 },
                { label: "冬季", value: 4 }
            ],
            addDialogVisible: false,
            currentRow: {}
        };
    },
    methods: {
        getList() {
            this.axios
                .post(URL.farm.farmSeedPage, {
                    ...this.search,
                    pageNo: this.currentPage,
                    pageSize: this.pageSize
                })
                .then(res => {
                    if (res.code === "0") {
                        this.tableData = res.data.records;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        edit(row) {
            this.addDialogVisible = true;
            this.currentRow = row;
        },

        onOffLine(row, status) {
            this.$confirm(
                `是否切换状态为${status == 1 ? "上" : "下"}架？`,
                `${status == 1 ? "上" : "下"}架提示`,
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.axios
                        .put(URL.farm.farmSeedUpdateStatus, {
                            id: row.id,
                            status
                        })
                        .then(res => {
                            if (res.code === "0") {
                                this.getList();
                                this.$message({
                                    type: "success",
                                    message: "操作成功!"
                                });
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },

        del(row) {
            this.$confirm(
                "删除后对应前端共享农场不可查看该土地，是否确认删除？",
                "删除提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.axios
                        .delete(`${URL.farm.farmSeedDelete}/${row.id}`)
                        .then(res => {
                            if (res.code === "0") {
                                this.getList();
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                });
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },

        closeAdd() {
            this.addDialogVisible = false;
            this.currentRow = {};
            this.getList();
        },

        reset() {
            this.search = {
                code: "",
                matchSeason: "",
                name: ""
            };
            this.getList();
        },

        handleSizeChange(val, type) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList();
        },

        handleCurrentChange(val, type) {
            this.currentPage = val;
            this.getList();
        }
    },
    mounted() {
        this.getList();
    }
};
</script>

<style lang="less">
.pages {
    .search {
        display: flex;
        justify-content: space-between;
    }
}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
